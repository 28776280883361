html {
	--blue: #3b81f0
}
* {
	margin: 0;
	padding: 0;
}
ul {
	list-style: none;
}
/* 隐藏百度地图左下角的logo和文本,如果css文件独立，记得把scoped关掉 */
.BMap_cpyCtrl {
	display: none;
}
.anchorBL {
	display: none;
}
/* ElementUi */
.el-dialog__body {
	padding: 0.5rem 1rem 1rem !important;
	font-size: 1rem !important;
}
/*下拉、级联菜单的bug*/
.el-cascader-menu, .el-cascader-menu__wrap {
	height: 340px !important;
}
.el-select-dropdown .el-scrollbar .el-scrollbar__wrap {
	overflow: scroll!important;
}

.contanier {
	/* background-color: white;
	border-radius: 5px; */
	box-sizing: border-box;
	padding: 10px;
}

.mr10 {
	margin-right: 10px !important;
}
.ml10 {
	margin-left: 10px !important;
}

.h100 {
	height: 100%;
}

.shadow-blue {
	box-shadow: 0 0 2px 2px #35a1ff;
}

/* ----------------背景--------------- */
.bg-blue {
	background-color: var(--blue);
}

.br5 {
	border-radius: 5px;
}
.fd-col {
	display: flex;
	flex-direction: column;
}
/* .full-screen {
	position: fixed;
	z-index: 999;
	top: 0;
	left: 0;
	width: 100% !important;
	height: 100% !important;
	background-color: #fff;
} */
/* -----------信息卡样式------------- */
.msg-card {
	position: relative;
	margin: 5px;
	color: #fff;
	padding: 10px 15px;
	display: flex;
	flex-direction: column;
	border-radius: 5px;
	box-shadow: 0px 0px 5px 1px #DBD8D8;
}

.msg-card__item {
	display: flex;
	justify-content: space-between;
	flex-grow: 1;
	padding-bottom: 3px;
}

.msg-card__item-child {
	display: flex;
	align-items: center;
}

.msg-card__item-title {
	white-space: nowrap;
}

.msg-card__item-text {
	display: flex;
	align-items: center;
	word-wrap: break-word;
	word-break: break-all;
	padding-left: 5px;
	flex-grow: 1;
}

.symbol-icon {
	width: 1em;
	height: 1em;
	vertical-align: -0.15em;
	fill: currentColor;
	overflow: hidden;
}
