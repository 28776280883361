@font-face {
  font-family: "iconfont"; /* Project id 2125581 */
  src: url('iconfont.woff2?t=1636600936943') format('woff2'),
       url('iconfont.woff?t=1636600936943') format('woff'),
       url('iconfont.ttf?t=1636600936943') format('truetype');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-shuaxin:before {
  content: "\e782";
}

.icon-home:before {
  content: "\e6b8";
}

.icon-type:before {
  content: "\e6c0";
}

.icon-dangan:before {
  content: "\e60d";
}

.icon-quyu:before {
  content: "\e605";
}

.icon-peizhi:before {
  content: "\e7b7";
}

.icon-sharpicons_building-:before {
  content: "\e79f";
}

.icon-yonghu:before {
  content: "\e633";
}

.icon-fasongxinxi:before {
  content: "\e6d3";
}

.icon-baogao:before {
  content: "\e624";
}

.icon-history-alarm:before {
  content: "\e651";
}

.icon-_xiangmuguanli:before {
  content: "\e601";
}

.icon-yonghu1:before {
  content: "\e60e";
}

.icon-xiangmuguanli:before {
  content: "\e611";
}

.icon-tongjifenxi-changguitongji:before {
  content: "\e626";
}

.icon-tongjifenxi-xiangmubiaogetongji:before {
  content: "\e627";
}

.icon-renkouguanli:before {
  content: "\e62a";
}

.icon-shebeiguanli1:before {
  content: "\e67f";
}

.icon-xiangmu_xiangmuguanli:before {
  content: "\e797";
}

.icon-meiyoushuju:before {
  content: "\e60b";
}

.icon-tongzhi:before {
  content: "\e984";
}

.icon-qingtian-yewan:before {
  content: "\e653";
}

.icon-qingtian-baitian:before {
  content: "\e654";
}

.icon-huojing:before {
  content: "\e6a0";
}

.icon-real-time-alarm:before {
  content: "\e64f";
}

.icon-guzhang1:before {
  content: "\e617";
}

.icon-alarm-full:before {
  content: "\e871";
}

.icon-quanping:before {
  content: "\e602";
}

